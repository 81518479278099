.wallet {
  text-align: center;
  &_banner {
    position: relative;
    z-index: 2;
    background-image: url("../../assets/PrivacyBanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 645px;
    @media screen and (max-width: 1140px) {
      height: 340px;
    }
    p {
      margin-top: unset;
      color: var(--white);
      max-width: 1100px;
      margin: 0 auto;
      padding: 0 20px;
      font-size: var(--largeParaGraph);
      line-height: 32px;
      text-align: center;
      @media screen and (max-width: 500px) {
        line-height: 22px;
      }
    }
    &_content {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;
    }
    h2 {
      color: var(--white);
      margin-bottom: 30px;
      font-size: var(--heading70);
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }
  }
  &_detail {
    position: relative;
    &::before {
      content: "";
      background: url(../../assets/homeBg.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      z-index: -9;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    padding: 100px 0 180px 0;
    @media (max-width: 1120px) {
      padding: 100px 20px 180px 20px;
    }
    @media (max-width: 991px) {
      padding: 60px 20px 60px 20px;
    }
    @media (max-width: 767px) {
      padding: 30px 20px 30px 20px;
    }

    h4 {
      font-size: var(--heading44);
      font-weight: 500;
      margin-bottom: 16px;
      margin-top: 40px;
      color: var(--headingColor);
    }
    h3 {
      color: #00bef2;
      text-align: center;
    }

    p {
      color: var(--commonPtext);
      line-height: 25px;
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      max-width: 1100px;
      margin: 0 auto;
      font-family: "Inter", sans-serif;
    }
  }
}
