.ourServices {
  background: linear-gradient(0deg, rgba(12, 16, 20, 0) 0%, #0f2237 100%);
  margin-top: 159px;
  padding-bottom: 159px;
  h1 {
    white-space: wrap;
  }
  p {
    color: var(--commonPtext);
    line-height: 25px;
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    font-family: "Inter", sans-serif;
  }
  @media screen and (max-width: 1340px) {
    margin-top: 100px;
    padding: 0 20px;
  }
  @media screen and (max-width: 679px) {
    margin-top: 40px;
  }
  &_head {
    text-align: center;
    padding: 80px 0;
    @media screen and (max-width: 1340px) {
      padding: 40px 0;
    }
    @media screen and (max-width: 679px) {
      padding: 20px 0;
    }
  }
}
