.bannerSection {
  position: relative;
  margin-bottom: 270px;
  @media screen and (max-width: 1340px) {
    margin-bottom: 125px;
  }
  @media screen and (max-width: 678px) {
    margin-bottom: 40px;
  }
  br {
    @media screen and (max-width: 1240px) {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    background-image: url(../../../../assets/hero-background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 1920px;
    width: 100%;
    height: 433px;
    z-index: -1;
    bottom: -420px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &_contant {
    padding-top: 116px;
    position: relative;
    z-index: 1;
    display: flex;
    gap: 94px;
    align-items: center;
    text-align: left;

    @media screen and (max-width: 1240px) {
      padding-top: 50px;
      flex-flow: wrap;
      flex-direction: column-reverse;
      text-align: center;
      gap: 24px;
    }
    @media screen and (max-width: 479px) {
      text-align: left;
    }
  }
  &_left {
    flex: 50%;
    &_button {
      margin-top: 50px;
      max-width: 156px;
      cursor: pointer;
      @media screen and (max-width: 1240px) {
        margin: 20px auto;
      }
      @media screen and (max-width: 479px) {
        margin: 5px auto;
      }
    }

    h1 {
      font-weight: 700;
      font-size: var(--heading64);
      line-height: 70px;
      white-space: nowrap;
      margin: 0;
      color: var(--white);
      margin-bottom: 33px;
      @media screen and (max-width: 1240px) {
        white-space: wrap;
        line-height: 61px;
      }
      @media (max-width: 768px) {
        margin-bottom: 23px;

        line-height: 44px;
        span {
          white-space: wrap;
        }
      }
      @media screen and (max-width: 479px) {
        line-height: 36px;
        margin-bottom: 10px;
      }
    }
    p {
      color: var(--white);
      line-height: 33px;
      font-weight: 400;
      @media screen and (max-width: 1240px) {
        white-space: wrap;
        line-height: 27px;
        margin-top: 10px;
      }
      @media (max-width: 991px) {
        margin: 20px 0;
        line-height: 25px;
      }
      @media screen and (max-width: 479px) {
        line-height: 20px;
        margin: 10px 0;
      }
    }
    &_links {
      margin-top: 50px;
      display: flex;
      gap: 20px;
      align-items: center;
      max-width: 236px;
      width: 100%;
      flex-flow: wrap;
      @media screen and (max-width: 1240px) {
        justify-content: center;
      }
    }
  }
  &_right {
    flex: 50%;

    img {
      max-width: 600px;
      width: 100%;
      @media screen and (max-width: 1240px) {
        max-width: 300px;
      }
    }
  }
}
