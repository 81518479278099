.aboutSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 143px;

  @media (max-width:1259px) {
    flex-direction: column !important;
    gap: 0px;

  }

  &_left {
    flex: 50%;
    img {
      max-width: 696px;
      width: 100%;

      @media (max-width:991px) {
        max-width: 400px;
      }
    }
  }

  &_right {
    flex: 47%;
    p {
      color: var(--commonPtext);
      line-height: 25px;
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      font-family: "Inter", sans-serif;
    }
    @media (max-width:580px) {
      flex: 100%;
    }

  }
}