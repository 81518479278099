.homeDiscription{
    max-width: 545px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 150px;
    @media (max-width: 568px) {
        padding: 15px 0;
      }
    img{
        margin-bottom: 22px;
    }
    h4{
        font-size: var(--heading26);
        color: var(--white);
        font-weight: 700;
        margin-top: 32px;
        @media (max-width: 568px) {
            margin: 15px 0;
          }
    }
    h6{
        font-size: var(--headingFour);
        font-weight: 600;
    }
    p {
        color: var(--commonPtext);
        line-height: 25px;
        font-size: var(--largeParaGraph16);
        font-weight: 400;
        font-family: "Inter", sans-serif;
      }
}