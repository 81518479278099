.contractDetails {
  ul {
    padding: 0;

    li {
      display: flex;
      gap: 32px;

      @media (max-width: 580px) {
        flex-wrap: wrap;
        text-align: center;
        gap: 12px;
        margin: 30px 0px;
      }

      img {
        background: #ffffff0d;
        padding: 18px;
        border-radius: 38px;
        border: 1px solid #ffffff1a;
        height: 40px;

        @media (max-width: 580px) {
          margin: auto;
        }
      }

      h4 {
        font-family: "Mulish", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: var(--white);
      }
    }
  }
}
