.commonAboutbar {
  h5 {
    color: var(--white);
    font-size: var(--headingFour);
    margin-bottom: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h6 {
    color: var(--white);
    font-size: var(--mediumParaGraph);
    margin-bottom: 28px;
    font-weight: 600;
    margin-top: 36px;
  }

  h1 {
    color: var(--white);
  }
}

.aboutCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;

  .commonCard {
    max-width: 243px;
    width: 100%;
    backdrop-filter: blur(20%);
  }
}
