@import "../../assets/var.scss";

.ContactUs {
  padding: 100px 0 100px 0;
  position: relative;
  &::before {
    content: "";
    background: url(../../assets/homeBg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -9;
    top: 0;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 991px) {
    margin: 60px 0 60px 0;
  }

  @media (max-width: 767px) {
    margin: 30px 0 30px 0;
  }

  p {
    color: var(--commonPtext);
    max-width: 983px;
    text-align: center;
    margin: 30px auto 60px auto;
    line-height: 25px;
    font-size: var(--largeParaGraph16);
  }

  h3 {
    font-size: var(--heading44);
    font-weight: 500;
    text-align: center;
    color: var(--headingColor);
  }
  button {
    margin-top: 50px;
    max-width: 155px;

    @media (max-width: 991px) {
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
  
}
.textAreaContact{
  height: 140px !important;
  padding: 16px 24px;
  border-radius: 10px;
}