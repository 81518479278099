.consumerOuterContainer {
  position: relative;
  z-index: 9;
  &::before {
    content: "";
    position: absolute;
    background-image: url(../../../../assets/CustomerDimg2.png);
    width: 485px;
    height: 485px;
    z-index: -1;
    bottom: -23px;
    left: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 1380px) {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    background-image: url(../../../../assets/CustomerDimg1.png);
    width: 485px;
    height: 485px;
    z-index: -1;
    top: 9px;
    right: 42px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 1380px) {
      display: none;
    }
  }
}
.customer {
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 82px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 26.384px;
  border: 0.563px solid rgba(255, 255, 255, 0.43);
  background: radial-gradient(
      162.83% 147.97% at 131.57% 149.24%,
      rgba(0, 190, 242, 0.5) 0%,
      rgba(103, 97, 223, 0) 100%
    ),
    linear-gradient(0deg, rgba(18, 20, 28, 0.6) 0%, rgba(18, 20, 28, 0.6) 100%),
    rgba(217, 217, 217, 0.01);
  backdrop-filter: blur(12.109271049499512px);

  @media screen and (max-width: 679px) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px;
  }
  @media screen and (max-width: 410px) {
    padding: 20px;
  }
  &_inner {
    display: flex;
    align-items: center;
    gap: 11px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 410px) {
      justify-content: space-around;
      width: 100%;
      text-align: end;
    }
    &:last-child {
      p {
        border-right: unset;
      }
    }
    h2 {
      font-size: var(--heading64);
    }
    p {
      color: var(--white);
      padding: 16px 57px 16px 0;
      border-right: 1px solid var(--grayBorder);
      @media (max-width: 708px) {
        padding: 16px 17px 16px 0;
      }
      @media screen and (max-width: 410px) {
        padding: 0;
        border-right: unset;
      }
    }
  }
}
