.footer_main {
  // position: absolute;
  background: var(--FooterBg);
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: auto;

  @media (max-width: 767px) {
    padding-top: 35px;
  }
  &_top{
    padding: 54px;
  }

  &__left {
    text-align: left;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 10px;

      img {
        margin: auto;
        display: block;
        margin-bottom: 20px;
      }
    }

    p {
      color: #fff;
      line-height: 25px;
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      font-family: "Inter", sans-serif;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  &__right {
    max-width: 300px;
    width: 100%;
    margin-left: auto;

    @media (max-width: 767px) {
      margin: 50px auto;
    }
   
    h2 {
      font-size: var(--headingFour);
      font-weight: 800;
      font-family: "mulish", sans-serif;
      color: #fff;
      text-align: left;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    svg {
      cursor: pointer;
    }

    > div {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      align-items: center;
      max-width: 270px;
      width: 100%;
      flex-wrap: wrap;
    
    }
  }

  &_copyright {
    background-color: rgba(0, 190, 242, 0.2);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 20px 36px 2px #0b182d26;
    p {
      font-size: var(--mediumParaGraph);
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-family: "inter", sans-serif;
    }
  }
}
