.roundedCR {
  text-align: center;
  padding-top: 100px;
  @media screen and (max-width: 991px) {
    padding-top: 40px;
  }
}

.carousel-container {
  position: relative;
  max-width: 1119px;
  margin-top: 100px;
  @media screen and (max-width: 991px) {
    margin-top: 40px;
  }
}
.ui-cards {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @media screen and (max-width: 991px) {
    padding-top: 100px;
  overflow: hidden;
  justify-content: center;
  }
}

.anticon {
  cursor: pointer;
  svg {
    width: 28px;
    height: 28px;
    fill: #fff;
    padding: 10px;
    background: #1d2025;
    border-radius: 15px;
  }
}
.inactiveCarausel {
  position: relative;
  height: 210px;
  width: 210px;
  z-index: 1;
  pointer-events: auto;
  transform: scale(0.8) translateY(6rem) translateX(0);
}
.activebg {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-image: url(../../assets/PolygonBg.png);
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(0.8) translateY(-6rem) translateX(0);
    height: 410px;
    width: 410px;
    rotate: 70deg;
    top: -83px;
    left: -109px;
  }
}
.activeCarausel {
  height: 340px;
  width: 340px;
  position: relative;
  transform: scale(0.8) translateY(-6rem) translateX(0);
  z-index: 2;
  pointer-events: auto;
 
}
.anticon{
  z-index: 100;
}
.anticon-caret-left {
  position: absolute;
  left: 30%;
  top: 10%;
  @media screen and (max-width: 991px) {
    left: 12%;
    top: 17%;
  }
}
.anticon-caret-right {
  position: absolute;
  right: 30%;
  top: 10%;
  @media screen and (max-width: 991px) {
    right: 12%;
    top: 17%;
  }
}
