.btnCustom.ant-btn {
    width: 100%;
    padding: 0px 14px;
    font-size: var(--largeParaGraph16);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.25s;
    border: 0px;
    box-shadow: none;
    border-radius: 10px;
    height: 50px;
    background: linear-gradient(126.29deg, #00BEF2 -16.02%, #008DB4 35.97%, #005360 96.02%);
    color: #fff !important;
    font-family: "Montserrat", sans-serif;
    &:disabled {
        opacity: 0.5;
        &:hover {
            opacity: 0.5;
            background: linear-gradient(126.29deg, #00BEF2 -16.02%, #008DB4 35.97%, #005360 96.02%) !important;
        }
    }

    &:hover {
        opacity: 0.9;
        background: linear-gradient(126.29deg, #005360 -16.02%, #008DB4 35.97%, #00BEF2 96.02%) !important;
        color: #fff !important;
    }

    .leftIcon {
        margin-right: 7px;
        display: flex;
    }

    .rightIcon {
        margin-left: 5px;
    }
}

.btnBorder {
    border: 1px solid var(--darkPurple) !important;
    background: var(--headerBg) !important;
    color: var(--darkPurple) !important;
}