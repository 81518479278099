.futureHeader {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: var(--HeaderBg);
  color: var(--white);
  padding: 23px 0px;

  &_Main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    &__headerlogo {
      img {
        max-width: 259px;
        width: 100%;
      }
    }

    &__rightSec {
      display: flex;
      gap: 50px;

      .active {
        color: var(--HoverAnchor);
      }

      a {
        font-weight: 400;
        font-size: var(--headingFour);
        color: #fff;

        &:hover {
          color: var(--HoverAnchor);
        }
      }
    }
  }

  &.scroll {
    background: var(--HeaderBg);
    box-shadow: 0 0 20px -10px var(--boxShadow);
  }

  &_lgheader {
    @media (max-width: 1140px) {
      display: none;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 1260px) {
      gap: 0px;
    }
  }
}

.headerlogo {
  img {
    max-width: 270px;
    width: 100%;
    height: 100%;

    @media (max-width: 1260px) {
      max-width: 200px;
    }
  }
}

.headerLink {
  &_menu {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;

    &_item {
      list-style: none;
      cursor: pointer;
      display: inline-block;
      padding: 20px;
      position: relative;
      transition: all 0.4s ease;
      font-size: var(--largeParaGraph16);
      font-weight: 500;

      @media (max-width: 1360px) {
        padding: 10px;
      }

      a {
        text-decoration: none;
        color: var(--whiteFFF);
        white-space: nowrap;

        &:hover {
          color: var(--mainTextColor);
          text-shadow: 0px 0px 20px var(--textShadow);
        }
      }

      .subMenu {
        left: 0;
        opacity: 0;
        position: absolute;
        padding: 21px !important;
        top: 88px;
        transform: scaleY(0);
        transform-origin: 0 0 0;
        transition: all 0.4s ease-in-out 0s;
        max-width: 270px;
        width: 100%;
        z-index: 99999 !important;
        text-align: left;
        visibility: hidden;

        li {
          list-style: none;
          padding: 10px 0;
        }
      }

      &:hover {
        .subMenu {
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }
  }
}

.menu {
  background: var(--buttonHoverBg);
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all 0.4s ease;

  @media (max-width: 1260px) {
    width: 35px;
    height: 35px;
  }

  &:hover {
    background: var(--buttonBg);
  }

  :first-child {
    width: 16px;
    margin-left: 5px;

    @media (max-width: 1260px) {
      width: 13px;
    }
  }

  :last-child {
    width: 16px;
    margin-left: 2px;

    @media (max-width: 1260px) {
      width: 13px;
    }
  }

  span {
    height: 2px;
    width: 25px;
    border-radius: 4px;
    background: var(--whiteFFF);

    @media (max-width: 1260px) {
      width: 20px;
    }
  }

  @media (max-width: 767px) {
    height: 40px;
    width: 40px;

    span {
      width: 20px;
    }

    :first-child {
      width: 12px;
      margin-left: 3px;
    }

    :last-child {
      width: 12px;
      margin-left: 1px;
    }
  }
}

.open {
  .input {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.bs-serach {
  overflow: hidden;
  position: relative;
}

.menuToggle {
  background: var(--darkPurple);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background: var(--buttonBg);
  }

  :first-child {
    width: 16px;
    margin-left: 5px;

    @media (max-width: 1260px) {
      width: 13px;
    }
  }

  :last-child {
    width: 16px;
    margin-left: 2px;

    @media (max-width: 1260px) {
      width: 13px;
    }
  }

  span {
    height: 2px;
    width: 25px;
    border-radius: 4px;
    background: var(--white);

    @media (max-width: 1260px) {
      width: 20px;
    }
  }

  @media (max-width: 767px) {
    height: 40px;
    width: 40px;

    span {
      width: 20px;
    }

    :first-child {
      width: 12px;
      margin-left: 3px;
    }

    :last-child {
      width: 12px;
      margin-left: 1px;
    }
  }
}