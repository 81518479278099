.aboutSec.container.reverse {
  flex-direction: row-reverse !important;
  margin-bottom: 199px;

  @media (max-width: 1259px) {
    flex-direction: column !important;
    gap: 0px;
    margin-bottom: 100px;
  }
}

.ant-drawer-content.futureHeader_Main__rightSec {
  background-color: var(--HeaderBg);

  .ant-drawer-close {
    svg {
      color: var(--white);
    }
  }

  .ant-drawer-body {
    flex-direction: column;
    padding-top: 0;

    a {
      display: block;
      margin: 0;
      padding: 10px;
      border-radius: 10px;

      &:hover {
        background: linear-gradient(
          126.29deg,
          #005360 -16.02%,
          #008db4 35.97%,
          #00bef2 96.02%
        ) !important;
        color: var(--white);
      }
    }
  }
}

.menuToggle {
  background: var(--darkBlue) !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
