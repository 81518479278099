.about {
  position: relative;
  &::before {
    content: "";
    background: url(../../assets/homeBg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -9;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &_banner {
    position: relative;
    z-index: 2;
    height: 645px;
    background-image: url("../../assets/AboutBanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-content: center;
    @media screen and (max-width: 1140px) {
      height: 340px;
    }
    &_content {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;
      @media screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
      }
    }
    h2 {
      color: var(--white);
      margin-bottom: 30px;
      font-size: var(--heading70);

    }
    p {
      margin-top: unset;
      color: var(--white);
      font-size: var(--heading26);
      line-height: 40px;
      text-align: center;
      padding: 0 20px;

      @media screen and (max-width: 500px) {
        line-height: 22px;
      }
    }
  }
  &_content {
    color: var(--white);
    text-align: left;
    padding: 50px 0 80px 0;

    @media (max-width: 991px) {
      padding: 60px 0 60px 0;
    }
    @media (max-width: 767px) {
      padding: 30px 0 30px 0;
    }
    h6 {
      font-size: var(--headingFour);
      font-weight: 600;
    }
    h4 {
      font-size: var(--heading44);
      font-weight: 500;
      margin-bottom: 16px;
      margin-top: 40px;
      color: var(--headingColor);
    }
    ul {
      padding-left: 18px;
      li {
        margin: 0 30px 0 0;

        &::marker {
          color: var(--darkBlue);
        }
      }
    }
    p {
      color: var(--commonPtext);
      line-height: 25px;
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      font-family: "Inter", sans-serif;
    }
    b {
      color: var(--darkBlue);
    }
    &_bottom {
      text-align: center;
      border-radius: 15.659px;
      border: 1.118px solid rgba(38, 41, 60, 0.7);
      background: #061220;
      padding: 50px;
      margin: 80px 0 117px 0;
      @media screen and (max-width: 991px) {
        padding: 20px;
        margin: 80px 0 0;
      }
      h5 {
        font-size: var(--heading26);
        font-weight: 400;
        margin-top: 30px;
        margin-bottom: 15px;

      }
      p {
        text-align: center;
      }
    }
  }
}
