.cardCarousel {
  overflow: hidden;
}
.react-multi-carousel-dot-list {
  bottom: 33px !important;
}
.react-multiple-carousel__arrow {
  display: none;
}
.ourSideCard {
  padding: 22px;
  max-width: 291px;
  width: 100%;

  img {
    width: 258px;
  }
  border: 1.12px solid;
  border-radius: 11.236px;
  border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );

  h4 {
    font-size: var(--headingThree);
    font-weight: 600;
    color: var(--white);
    margin: 11px 0;
  }

  &_bottom {
    margin-top: 22px;

    &_btn {
      margin-top: 23px;

      .btnCustom {
        max-width: 116px;
        height: 34px;
        background: var(--white);
        color: var(--black) !important;
        color: var(--commonPtext);
        line-height: 25px;
        font-size: var(--largeParaGraph16);
        font-weight: 400;
      }
    }
  }
}

.react-multiple-carousel__arrow {
  z-index: 9;
}
.react-multi-carousel-list {
  justify-content: space-between;
  gap: 20px;
}
.react-multi-carousel-track {
  padding-bottom: 117px !important;
  gap: 20px;
  li {
    margin: 0 20px;
    cursor: pointer;
  }
}
.main_sliderinner {
  overflow: hidden;
  padding: 20px;
  height: 371px;
  max-width: 258px;
  width: 100%;
  border-radius: 11.236px;
  border: 1px solid rgb(100, 95, 95);
  background: #12141c;

  &.active {
    background: radial-gradient(
      162.83% 147.97% at 131.57% 149.24%,
      rgba(0, 190, 242, 0.5) 0%,
      rgba(0, 190, 242, 0.09) 4.14%,
      rgba(103, 97, 223, 0) 100%
    );
  }
  &__image {
    background-color: #000;
    border: 0.1px solid rgb(36, 34, 34);
    border-radius: 10px;
    height: 181px;
    max-width: 258px;
    width: 100%;
    overflow: hidden;

    img {
      max-width: 258px;
      width: 100%;
      margin: auto;
    }
  }

  .desc {
    max-width: 258px;
    width: 100%;
    padding-top: 20px;
    h3 {
      color: #fff;
      font-size: var(--headingTwo);
      margin-bottom: 10px;
    }

    p {
      font-size: var(--largeParaGraph16);
    }
  }
}
