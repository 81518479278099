.forQuries {
  position: relative;
  padding-bottom: 182px;
  &::after {
    content: "";
    position: absolute;
    background-image: url(../../assets/hero-background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 1920px;
    width: 100%;
    height: 433px;
    z-index: -1;
    bottom: -280px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__bg {
    display: flex;
    background: url("../../assets/quries.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    height: 320px;
    width: 100%;
    @media screen and (max-width: 1140px) {
      height: 100%;
      padding: 40px;
      width: unset;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }
    > div {
      flex: 50%;
    }

    &__left {
      position: relative;
      @media screen and (max-width: 767px) {
        position: unset;
        text-align: center;
      }

      img {
        position: absolute;
        top: -20px;
        left: 100px;
        @media screen and (max-width: 1140px) {
          width: 300px;
          top: -58px;
          left: 0px;
        }
        @media screen and (max-width: 680px) {
          width: 250px;
          top: -44px;
          left: 0px;
        }
        @media screen and (max-width: 767px) {
          position: unset;
          margin-bottom: 20px;
         
        }
      }
    }

    &__right {
      display: flex;
      justify-content: left;
      align-items: center;
      text-align: center;
      margin-right: 30px;

      @media screen and (max-width: 1140px) {
        justify-content: end;
      }
      > div {
        .btnCustom {
          margin: 0 auto;
        }
      }
      p {
        margin-bottom: 18px;
        color: var(--white) !important;
      }
      h2 {
        color: #fff;
        font-size: var(--heading48);
        margin-bottom: 25px;
      }
    }
  }
}
