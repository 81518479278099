.home {
  position: relative;
  &::before {
    content: "";
    background: url(../../assets/homeBg.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -9;
    top: 0;
    height: 100%;
    width: 100%;
  }

  h1 {
    color: var(--white);
    font-size: var(--heading48);
    font-weight: 800;
    margin-bottom: 28px;
    white-space: nowrap;
  }
}
