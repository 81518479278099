.commonCard{
    padding: 22px 17px;
    border: 1px solid var(--grayBorder);
    box-shadow: 0px 1px 2px 0px #0000000D;
    border-radius: 12px;
    width: 100%;
    &_top{
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 13px;
        h6{
            margin: 0 !important;
        }
    }
}