.wallet_page {
  background: url("../../assets/walletBg.png");
  background-size: cover;
  background-repeat: no-repeat;

  p {
    color: var(--commonPtext);
    line-height: 25px;
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    font-family: "Inter", sans-serif;
  }

  .aboutSec {
    margin-bottom: 200px;

    @media (max-width: 1259px) {
      margin-bottom: 100px;
    }

    &_right {
      ul {
        padding-left: 10px;

        li::marker {
          color: var(--darkBlue);
        }

        li {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.desc_feature {
  border-radius: 15.659px;
  border: 1.118px solid rgba(38, 41, 60, 0.7);
  background: #061220;
  padding: 66px 106px;
  margin-bottom: 106px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  p {
    color: var(--coreGray);
    text-align: center;
    font-family: "inter", sans-serif;
    font-size: var(--largeParaGraph16);
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
  }

  h2 {
    font-size: var(--heading64);
    font-weight: 700;
    text-align: center;
    color: var(--white);
    margin-bottom: 30px;

    span {
      color: var(--darkBlue);
    }
  }
}

// Phon group component
.group_phones {
  padding-top: 60px;

  .noteText {
    color: var(--white);
    text-align: left;
    display: flex;
    margin-bottom: 40px;

    b {
      white-space: nowrap;
      color: var(--darkBlue);
    }

    ul {
      margin: 0;
      padding-left: 10px;

      li {
        list-style: none;
      }
    }

  }

  .cellphone {
    display: block;
    margin: auto;
    width: 100%;
  }

  div {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 48px;

    img {
      border-radius: 10px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

//walletbanner
.walletBanner {
  display: flex;
  gap: 144px;
  align-items: center;
  padding: 144px 0;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    padding: 75px 0;
  }

  @media (max-width: 1259px) {
    gap: 70px;
  }

  h2 {
    font-size: var(--heading64);
    color: var(--white);
    margin-bottom: 30px;

    span {
      color: var(--darkBlue);
    }
  }

  >div {
    flex: 50%;

    img {
      margin: auto;
      display: block;

      @media (max-width: 1259px) {
        height: 230px;
      }
    }
  }
}