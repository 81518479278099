// @import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url(" https: //fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  margin: 0;
  font-family: "Mulish", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Color vars//
  --darkBlue: #00bef2;
  --darkPurple: #0000b2;
  --HoverAnchor: #00bef2;
  --FooterBg: #0d1b26;
  --HeaderBg: #05293c;
  --brandPurple10: #5700ff10;
  --coreGray: #9ba0a6;
  --offWhite: #f9f9fd;
  --black: #000000;
  --mutedBlack: rgba(0, 0, 0, 0.5);
  --white: #ffffff;
  --validation: #ffb82d;
  --error: #f93232;
  --success: #00b25d;
  --disabledButton: #5500ff10;
  --blue: #4285f4;
  --lightPink: #f8f8fc;
  --black60: #00000099;
  --black40: #00000040;
  --grayBorder: #ffffff1a;
  --grayE9E9EF: #e9e9ef;
  --grayDark636364: #636364;
  --gray666666: #666666;
  --commonPtext: #9ba0a6;
  --headingColor: #ECECEC;

  // Typography //
  --heading100: 100px;
  --heading70: 70px;
  --heading64: 64px;
  --heading48: 48px;
  --heading44: 44px;
  --heading40: 40px;
  --headingOne: 32px;
  --heading26: 26px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 14px;
  --smallParagraph: 13px;
}

/* Responsive styles */
@media screen and (max-width: 1240px) {
  :root {
    --heading64: 52px;
    --heading48: 34px;
    --heading70: 50px;
    --heading44: 34px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --heading64: 43px;
    --heading100: 70px;
    --heading48: 28px;
    --heading44: 28px;
    --heading40: 32px;
    --headingOne: 28px;
    --heading26: 18px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
    --heading70: 30px;

  }
}

@media screen and (max-width: 479px) {
  :root {
    --heading64: 32px;
    --heading100: 40px;
    --heading48: 28px;
    --heading44: 22px;
    --heading40: 20px;
    --headingOne: 24px;
    --headingTwo: 18px;
    --heading26: 16px;
    --headingThree: 20px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 13px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: "Mulish", sans-serif;
  margin: 0;
  padding: 0;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #181818;
}

#root {
  width: 100%;
}

// img {
//   width: 100%;
// }

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

h2.ant-typography {
  font-size: var(--heading70);
  margin: unset;
  line-height: unset;
  text-align: center;
}

h3.ant-typography {
  font-size: var(--heading40);
  margin: unset;
  line-height: unset;
  color: var(--darkBlue);
}

.container {
  padding: 0 20px;
  max-width: 1400px;
  margin: auto;
}

.bannerBlueText {
  color: var(--darkBlue);
  white-space: nowrap;

  @media (max-width: 480px) {
    white-space: wrap;
  }
}

.form-outer {
  // max-width: 690px;
  background-color: rgba(6, 18, 32, 1);
  border-radius: 15px;
  padding: 60px 40px;
  border: 1px solid rgba(38, 41, 60, 0.7);
  margin: auto;

  @media (max-width: 991px) {
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
  }

  textarea {
    background-color: #131e2b;
    border: 1px solid rgba(38, 41, 60, 0.7);
    color: white;

    &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
      background-color: #131e2b;
    }

    &.ant-input-outlined {

      &:focus,
      &:hover,
      &:focus-within {
        background-color: #131e2b;
        border-color: var(--darkBlue);
      }
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .ant-form-item {
    .ant-form-item-label>label {
      color: rgba(255, 255, 255, 0.5);
    }

    .ant-form-item-explain-error {
      text-align: initial;
      font-size: 12px;
    }
  }

  button {
    margin: auto;
  }
}

.customInput {
  input.ant-input {
    background-color: #131e2b;
    padding: 15px 18px;
    border: unset;
    color: white;
    border-radius: 10px;

    @media (max-width: 991px) {
      padding: 12px 15px;
    }

    @media (max-width: 767px) {
      padding: 10px 12px;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus,
    &:hover,
    &:focus-within {
      background-color: #131e2b;
      border-color: var(--darkBlue);
    }
  }

  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: #131e2b;
  }

  .ant-input-outlined {
    border: unset;
  }
}